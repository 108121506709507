.btn {
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  border-radius: 2px;
  &--primary {
    padding: 10px 10px;
    background: $primary-brand-color;
    transition: all 0.3s ease;
    &:hover {
      background: darken($primary-brand-color, 10%);
      // background: $primary-brand-color-dark;
    }
  }
  &--secondary {
    padding: 10px 10px;
    background: $secondary-brand-color;
    transition: all 0.3s ease;
    // color: #fff;
    &:hover {
      background: darken($secondary-brand-color, 15%);
      // background: $primary-brand-color-dark;
    }
  }
  &--teritary {
    color: #373f48;
    padding: 7px 15px;
    background: $white;
    border: 1px solid #869399;
    transition: all 0.3s ease;
    &:hover {
      background: #f4f8f9;
      // background: $primary-brand-color-dark;
    }
  }
  &--large {
    width: 100%;
    padding: 11px;
  }
  &--small {
    padding: 8px 10px;
  }
}
.btn-custom {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  box-sizing: border-box;
  border-radius: 15px;
  border-radius: 15px;
  padding: 4px 12px;
  font-weight: normal;
  &.active {
    color: #00A558;
    background: #E9F9F2;
    border: 1px solid #00A558;
  }
  &.inactive {
    color: #F93D49;
    background: rgba(249, 61, 73, 0.1);
    border: 1px solid #F93D49;
  }
  &.certified {
    color: #003EDF;
    border: 1px solid #003EDF;
    background: #F7F9FF;
  }
  &.ready {
    color:#F5A91A;
    background: #FCF5CA;
    border: 1px solid #F5A91A;
  }
}
.btn-cancel{
  border: 1px solid #869399;
  box-sizing: border-box;
  border-radius: 2px;
  color: #373F48;
  padding: 5px 10px;
  font-weight: normal;

}